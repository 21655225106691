import _loggers2 from "./loggers.js";
import _Uppy from "./Uppy.js";
import _UIPlugin from "./UIPlugin.js";
import _BasePlugin from "./BasePlugin.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Uppy = exports.UIPlugin = exports.BasePlugin = void 0;
Object.defineProperty(exports, "debugLogger", {
  enumerable: true,
  get: function () {
    return _loggers.debugLogger;
  }
});
var _loggers = _loggers2;
exports = _Uppy;
const _0 = _UIPlugin;
exports.UIPlugin = _0;
const _1 = _BasePlugin;
exports.BasePlugin = _1; // TODO: remove all the following in the next major

/* eslint-disable import/first */

const Uppy = _Uppy;
exports.Uppy = Uppy;
const UIPlugin = _UIPlugin;
const BasePlugin = _BasePlugin; // Backward compatibility: we want those to keep being accessible as static
// properties of `Uppy` to avoid a breaking change.

Uppy.Uppy = Uppy;
Uppy.UIPlugin = UIPlugin;
Uppy.BasePlugin = BasePlugin;
Uppy.debugLogger = _loggers.debugLogger;
export default exports;
export const __esModule = exports.__esModule,
      debugLogger = exports.debugLogger;
const _Uppy2 = exports.Uppy,
      _UIPlugin2 = exports.UIPlugin,
      _BasePlugin2 = exports.BasePlugin;
export { _Uppy2 as Uppy, _UIPlugin2 as UIPlugin, _BasePlugin2 as BasePlugin };